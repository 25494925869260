import revive_payload_client_6rWR8VxWsR from "/app/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.4.5_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_kuCG79TyxX from "/app/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.4.5_typescript@5.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_IbBGvdQenf from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.1.6_vue@3.3.9/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import unhead_ILtjLLoeDX from "/app/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.4.5_typescript@5.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_uAIhaN40ak from "/app/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.4.5_typescript@5.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_B86mNmBton from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.3.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_Ky8c7h5ely from "/app/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.4.5_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import axios_QMFgzss1s4 from "/app/plugins/axios.ts";
import nuxt_i18n_LttGd3AaOh from "/app/plugins/nuxt-i18n.ts";
export default [
  revive_payload_client_6rWR8VxWsR,
  router_kuCG79TyxX,
  plugin_vue3_IbBGvdQenf,
  components_plugin_KR1HBZs4kY,
  unhead_ILtjLLoeDX,
  prefetch_client_uAIhaN40ak,
  plugin_client_B86mNmBton,
  chunk_reload_client_Ky8c7h5ely,
  axios_QMFgzss1s4,
  nuxt_i18n_LttGd3AaOh
]