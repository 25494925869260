import { defineStore } from 'pinia';
import { getMessageTranslation } from '~/hooks/useMessageTranslation';

export type UserType = {
    id?: number,
    password?: string,
    last_login?: string,
    is_superuser?: true,
    first_name?: string,
    last_name?: string,
    is_staff?: true,
    date_joined?: string,
    email?: string,
    is_active?: true,
    is_activated?: true,
    created_at?: string,
    updated_at?: string,
    sex?: 1,
    role?: string,
    avatar?: string,
    img_width?: number,
    img_height?: number,
    activation_code?: string,
    middle_name?: string,
    activated_at?: string,
    email_notify_moderation?: true,
    email_notify_sales?: true,
    email_notify_promo?: true,
    email_notify_new_login?: true,
    phone_notify_moderation?: true,
    phone_notify_sales?: true,
    phone_notify_promo?: true,
    phone_notify_new_login?: true,
    about?: string,
    offline_contact?: string,
    groups?: number[],
    user_permissions?: number[],
    friends?: number[],
    authenticated?: boolean
};

type SecurityData = {
    password?: string
    new_password?: string
}

export const useUserData = defineStore('userdata', {
    state: () => { 
        return {
            authenticated: false,
            loading: false,
            error: false,
            message: {} as any,
            userData: {} as UserType,
        }
    },
    actions: {
        logOut() {
            if (process.client) localStorage.removeItem('userData');
            this.userData = {};
            this.authenticated = false;
            useRouter().push('/login')
            return
        },
        async getRefreshToken() {
            let storage: any;
            if (process.client) { 
                storage = localStorage.getItem('userData');
            };
            if (storage) { 
                const userLocal = JSON.parse(storage);
                const refreshToken = userLocal.refresh;
                try {
                    const response: any = await useApiFetch('/token/refresh/', {
                        method: 'POST',
                        data: { "refresh": refreshToken },
                        headers: { 'Content-Type': 'application/json' }
                    });
                    if (response.data.access) { 
                        localStorage.removeItem('userData');
                        let userDataUpdate = {
                            ...userLocal,
                            access: response.data.access,
                        };

                        if(process.client) localStorage.setItem('userData', JSON.stringify(userDataUpdate)); 
                    }                       
                } catch (error: any) {
                    this.showErrorMessage({ errorRefresh: "Требуется повторный вход" });
                    this.logOut();
                }                
            };
        },        
        showErrorMessage(mes: any) { 
            this.message = { ...this.message, ...mes };
            setTimeout(() => this.message = {}, 4000);
            return;
        },
        async loginUser(user: UserType) { 
            let userData = {
                email: user.email,
                password: user.password
            };         
            let postData = userData;            
            try {
                const response: any = await useApiFetch('/token/', {
                    method: 'POST',
                    data: postData,
                    headers: { 'Content-Type': 'application/json' }
                });

                if (response.data) {
                    let tokenData = {
                        id: response.data.user_id,
                        email: userData.email,
                        access: response.data.access,
                        refresh: response.data.refresh,
                        authenticated: true,
                    };
                    if(process.client) localStorage.setItem('userData', JSON.stringify(tokenData));
                    this.authenticated = true;                    
                    useRouter().push('/')
                } else {
                    this.message = {errorMessage: "Ошибка входа в систему"}
                    setTimeout(() => this.message = {}, 3000)
                }
            } catch (error) {
                console.error(error);
                this.showErrorMessage({ errorMessage: "Ошибка входа в систему, проверьте данные" });
            };            
        },
        async passwordResetViaEmail(email: string) {             
            try {
                const response: any = await useApiFetch('/users/recovery_password_request', {
                    method: 'POST',
                    data: { email },
                    headers: { 'Content-Type': 'application/json' }
                });
                if (response.data) return { message: "Отправлена ссылка на почту" };
            } catch (error) {
                console.error(error);
                this.showErrorMessage({ errorMessage: "Возникла ошибка при отправке данных" });
            };            
        },
        async createNewPasswordAfterReset({ new_password, recovery_code, email }: { new_password: string, recovery_code: string, email: string }) {
            try {
                const response: any = await useApiFetch('/users/recovery_password_update', {
                    method: 'PATCH',
                    data: {
                        email,
                        new_password,
                        recovery_code,
                    },
                    headers: { 'Content-Type': 'application/json' }
                });
                if (response.data) return { message: "Пароль успешно изменен" };
            } catch (error) {
                console.error(error);
                if (error.response.status === 422) return this.showErrorMessage({ errorMessage: "Пароль слишком простой, придумайте новый" }); 
                this.showErrorMessage({ errorMessage: "Возникла ошибка при отправке данных" });
            };
        },
        async registerUser(user: UserType) {            
            try {
                const response = await useApiFetch('/registration', {
                    method: 'POST',
                    data: user,
                    headers: {
                        'Content-Type': 'application/json;',
                    }
                });
                if (response.data) {
                    let tokenData = {
                        email: user.email,
                        access: response.data.tokens.access,
                        refresh: response.data.tokens.refresh,
                        authenticated: true,
                    }
                    if (process.client) localStorage.setItem('userData', JSON.stringify(tokenData));
                    this.authenticated = true;                 
                    return { message: 'Учетная запись создана' };
                };
            } catch (error) {
                console.error(error)
                this.showErrorMessage({ errorMessage: "Ошибка регистрации пользователя" });
            };
        },
        async getUser() { 
            let storage: any;
            if (process.client) { 
                storage = localStorage.getItem('userData');
            };
            if (storage) { 
                try {
                    const userLocal = JSON.parse(storage);
                    const response = await useApiFetch('/profile/me', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json;',
                            'Authorization': `Bearer ${userLocal.access}`
                        },
                    });
                    if (response.data) {                         
                        return this.userData = response.data;
                    }
                } catch (error: any) {
                    console.error(error);
                    await this.getRefreshToken();
                }                  
            };
        },
        async changePassword(postData: SecurityData) {
            let storage: any;
            if (process.client) { 
                storage = localStorage.getItem('userData');
            };            
            if (storage) { 
                try {
                    const userLocal = JSON.parse(storage);
                    const response: any = await useApiFetch('/profile/change_password', {
                        method: 'POST',
                        data: postData,
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${userLocal.access}`
                        }
                    });
                    if (response.data.message) {
                        return { message: 'Данные учетной записи обновлены' };
                    };
                } catch (error: any) {
                    console.error(error);
                    await this.getRefreshToken();
                    this.showErrorMessage({ errorMessageOfChangePassword: getMessageTranslation(error.response.data.detail) });
                } 
            }           
        },       
        autoLoginUser() {
            let storage: any;
            if (process.client) { 
                storage = localStorage.getItem('userData');
            };             
            if (storage) { 
                const userLocal = JSON.parse(storage);
                if (userLocal.authenticated) {
                    this.authenticated = true;
                };
                return;
            }
            return;
        },        
        showLoading(toggle: boolean) { 
            return this.loading = toggle
        },
        async updatePesonData(additionalData: any, idFrom: string) { 
            const store = useUserData();
            let formUpdateUser = document.querySelector(`#${idFrom}`) as any;
            let formData = new FormData(formUpdateUser);
            if (additionalData.contacts) {
                formData.append("offline_contact", JSON.stringify(additionalData.contacts)); 
            };
            let storage: any;
            if (process.client) { 
                storage = localStorage.getItem('userData');
            };   
            if (storage) { 
                try {
                    this.loading = true;
                    const userLocal = JSON.parse(storage);
                    const response = await useApiFetch('/profile/update/', {
                        method: 'PATCH',
                        data: formData,
                        headers: {
                            'Content-Type': 'multipart/form-data;boundary=----',
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${userLocal.access}`
                        },
                        redirect: 'follow'
                    });
                    
                    if (response.data) {
                        return { updateSuccess: "Изменения сохранены" } || "";
                    };
                } catch (error: any) {
                    console.error(error);
                    await store.getRefreshToken();
                    this.showErrorMessage({ errorMessage: "Возникла ошибка при отправке данных" });
                } finally {
                    this.loading = false;
                }
            }
        },
    },
})