<template>
  <div>
    <NuxtLayout>
      <NuxtPage/>
    </NuxtLayout>
  </div>
</template>

<script setup>
import { useUserData } from '~~/stores/userData'
const store = useUserData()

useHead({
  title: "Артель",
  meta: [{ name: "description", content: "Точка сборки русского современного искусства" }],
});

onMounted(() => {
  store.autoLoginUser();
});
</script>
