<template>
    <div class="mt-7 max-w-sm mx-auto text-center card">
        <p class="mt-7 text-7xl font-bold">{{ error.statusCode }}</p>
        <p class="mt-6 text-6xl">Ooops.</p>
        <p class="mt-7">{{ error.message }}</p>
        <button class="btn my-7" @click="handleClickError">Go home...</button>
    </div>
</template>

<script setup>
defineProps(['error'])

const handleClickError = () => clearError({ redirect: "/" })
</script>

<style scoped></style>