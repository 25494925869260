export function buildApiBaseUrl(version: string) {
    const config = useRuntimeConfig();
    const host = config.public.APP_HOST;
    let baseUrl = config.public.API_URL;

    if (!baseUrl.startsWith("http") && baseUrl.indexOf(host) === -1) {
        baseUrl = host + baseUrl;
    }

    if (baseUrl.startsWith("http") === false) {
        baseUrl = "http://" + baseUrl;
    }

    if (baseUrl.endsWith("/") === false) {
        baseUrl += "/";
    }

    try {
        return new URL(version, baseUrl);
    } catch (error) {
        console.error(error);
    }
}
