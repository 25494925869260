const version = 'v1'; //TODO: passing version as arg

export const useApiFetch = (request: string, opts?: {}) => {
  const axios = useNuxtApp().$axios as any;
  const config = useRuntimeConfig();
  let baseURL: string | undefined;
  if ((request as string).startsWith(config.public.API_URL) === true) {
    baseURL = '';
  } else {
    baseURL = buildApiBaseUrl(version)?.href;
  }

  if (baseURL === undefined) {
    baseURL = '';
  }

  return axios(`${baseURL}${request}`, opts);
}
