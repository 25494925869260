export const translations = {
  'gallery': 'Галерея',
  'members': 'Участники',
  'about': 'О нас',
  'dashboard': 'Личный кабинет',
  'events': 'События',
  'open-calls': 'Мероприятия',
  'settings': 'Настройки',
  'contacts': 'Контакты',
  'subscribers': 'Подписчики',
  'friends': 'Друзья',
  'subscriptions': 'Подписки',
  'favorites': 'Избранное',
  'collection': 'Моя коллекция',
  'authors': 'Участники',
  'login': "Вход",
  'registration': "Регистрация"
}

export default defineNuxtPlugin(nuxtApp => {
  return {
    vueI18n: {
      fallbackLocale: 'ru',
      messages: {
        ru: translations,
      },
    },
  }
})
