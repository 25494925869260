import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as _91id_93wn4LweVvF4Meta } from "/app/pages/authors/[id].vue?macro=true";
import { default as indexKQal723sWyMeta } from "/app/pages/authors/index.vue?macro=true";
import { default as confirmiabBgwNTSOMeta } from "/app/pages/confirm.vue?macro=true";
import { default as contactsHSHl3sOssUMeta } from "/app/pages/contacts.vue?macro=true";
import { default as indexcf29WHm89pMeta } from "/app/pages/dashboard/collection/index.vue?macro=true";
import { default as indexYZyubdmU2aMeta } from "/app/pages/dashboard/events/index.vue?macro=true";
import { default as indexeZbnvOMChjMeta } from "/app/pages/dashboard/favorites/index.vue?macro=true";
import { default as indexFMESagEF94Meta } from "/app/pages/dashboard/friends/index.vue?macro=true";
import { default as indexhg2nWJrbRRMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as indexvBND1iL2DpMeta } from "/app/pages/dashboard/information/index.vue?macro=true";
import { default as indexMz7ffZfliDMeta } from "/app/pages/dashboard/settings/index.vue?macro=true";
import { default as index5o0pEK4kWkMeta } from "/app/pages/dashboard/subscribers/index.vue?macro=true";
import { default as indexwdtbSWHTQVMeta } from "/app/pages/dashboard/subscriptions/index.vue?macro=true";
import { default as _91id_93X0AUauIQxeMeta } from "/app/pages/gallery/[id].vue?macro=true";
import { default as indextDyoPT7UeiMeta } from "/app/pages/gallery/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexUH62nDhvWJMeta } from "/app/pages/login/change-password/index.vue?macro=true";
import { default as _91password_93LYR8HUijsPMeta } from "/app/pages/login/email/[password].vue?macro=true";
import { default as indexf7W4xrWuMDMeta } from "/app/pages/login/email/index.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as _91id_937ZjP19jqf6Meta } from "/app/pages/open-calls/[id].vue?macro=true";
import { default as indexZvpMAgx9f3Meta } from "/app/pages/open-calls/index.vue?macro=true";
import { default as registrationre4qBsMVLdMeta } from "/app/pages/registration.vue?macro=true";
import { default as selectionsgNFlPlxpAUMeta } from "/app/pages/selections.vue?macro=true";
export default [
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about",
    path: aboutu8Sb32ihwPMeta?.path ?? "/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect || undefined,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91id_93wn4LweVvF4Meta?.name ?? "authors-id",
    path: _91id_93wn4LweVvF4Meta?.path ?? "/authors/:id()",
    meta: _91id_93wn4LweVvF4Meta || {},
    alias: _91id_93wn4LweVvF4Meta?.alias || [],
    redirect: _91id_93wn4LweVvF4Meta?.redirect || undefined,
    component: () => import("/app/pages/authors/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKQal723sWyMeta?.name ?? "authors",
    path: indexKQal723sWyMeta?.path ?? "/authors",
    meta: indexKQal723sWyMeta || {},
    alias: indexKQal723sWyMeta?.alias || [],
    redirect: indexKQal723sWyMeta?.redirect || undefined,
    component: () => import("/app/pages/authors/index.vue").then(m => m.default || m)
  },
  {
    name: confirmiabBgwNTSOMeta?.name ?? "confirm",
    path: confirmiabBgwNTSOMeta?.path ?? "/confirm",
    meta: confirmiabBgwNTSOMeta || {},
    alias: confirmiabBgwNTSOMeta?.alias || [],
    redirect: confirmiabBgwNTSOMeta?.redirect || undefined,
    component: () => import("/app/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: contactsHSHl3sOssUMeta?.name ?? "contacts",
    path: contactsHSHl3sOssUMeta?.path ?? "/contacts",
    meta: contactsHSHl3sOssUMeta || {},
    alias: contactsHSHl3sOssUMeta?.alias || [],
    redirect: contactsHSHl3sOssUMeta?.redirect || undefined,
    component: () => import("/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: indexcf29WHm89pMeta?.name ?? "dashboard-collection",
    path: indexcf29WHm89pMeta?.path ?? "/dashboard/collection",
    meta: indexcf29WHm89pMeta || {},
    alias: indexcf29WHm89pMeta?.alias || [],
    redirect: indexcf29WHm89pMeta?.redirect || undefined,
    component: () => import("/app/pages/dashboard/collection/index.vue").then(m => m.default || m)
  },
  {
    name: indexYZyubdmU2aMeta?.name ?? "dashboard-events",
    path: indexYZyubdmU2aMeta?.path ?? "/dashboard/events",
    meta: indexYZyubdmU2aMeta || {},
    alias: indexYZyubdmU2aMeta?.alias || [],
    redirect: indexYZyubdmU2aMeta?.redirect || undefined,
    component: () => import("/app/pages/dashboard/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexeZbnvOMChjMeta?.name ?? "dashboard-favorites",
    path: indexeZbnvOMChjMeta?.path ?? "/dashboard/favorites",
    meta: indexeZbnvOMChjMeta || {},
    alias: indexeZbnvOMChjMeta?.alias || [],
    redirect: indexeZbnvOMChjMeta?.redirect || undefined,
    component: () => import("/app/pages/dashboard/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: indexFMESagEF94Meta?.name ?? "dashboard-friends",
    path: indexFMESagEF94Meta?.path ?? "/dashboard/friends",
    meta: indexFMESagEF94Meta || {},
    alias: indexFMESagEF94Meta?.alias || [],
    redirect: indexFMESagEF94Meta?.redirect || undefined,
    component: () => import("/app/pages/dashboard/friends/index.vue").then(m => m.default || m)
  },
  {
    name: indexhg2nWJrbRRMeta?.name ?? "dashboard",
    path: indexhg2nWJrbRRMeta?.path ?? "/dashboard",
    meta: indexhg2nWJrbRRMeta || {},
    alias: indexhg2nWJrbRRMeta?.alias || [],
    redirect: indexhg2nWJrbRRMeta?.redirect || undefined,
    component: () => import("/app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexvBND1iL2DpMeta?.name ?? "dashboard-information",
    path: indexvBND1iL2DpMeta?.path ?? "/dashboard/information",
    meta: indexvBND1iL2DpMeta || {},
    alias: indexvBND1iL2DpMeta?.alias || [],
    redirect: indexvBND1iL2DpMeta?.redirect || undefined,
    component: () => import("/app/pages/dashboard/information/index.vue").then(m => m.default || m)
  },
  {
    name: indexMz7ffZfliDMeta?.name ?? "dashboard-settings",
    path: indexMz7ffZfliDMeta?.path ?? "/dashboard/settings",
    meta: indexMz7ffZfliDMeta || {},
    alias: indexMz7ffZfliDMeta?.alias || [],
    redirect: indexMz7ffZfliDMeta?.redirect || undefined,
    component: () => import("/app/pages/dashboard/settings/index.vue").then(m => m.default || m)
  },
  {
    name: index5o0pEK4kWkMeta?.name ?? "dashboard-subscribers",
    path: index5o0pEK4kWkMeta?.path ?? "/dashboard/subscribers",
    meta: index5o0pEK4kWkMeta || {},
    alias: index5o0pEK4kWkMeta?.alias || [],
    redirect: index5o0pEK4kWkMeta?.redirect || undefined,
    component: () => import("/app/pages/dashboard/subscribers/index.vue").then(m => m.default || m)
  },
  {
    name: indexwdtbSWHTQVMeta?.name ?? "dashboard-subscriptions",
    path: indexwdtbSWHTQVMeta?.path ?? "/dashboard/subscriptions",
    meta: indexwdtbSWHTQVMeta || {},
    alias: indexwdtbSWHTQVMeta?.alias || [],
    redirect: indexwdtbSWHTQVMeta?.redirect || undefined,
    component: () => import("/app/pages/dashboard/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93X0AUauIQxeMeta?.name ?? "gallery-id",
    path: _91id_93X0AUauIQxeMeta?.path ?? "/gallery/:id()",
    meta: _91id_93X0AUauIQxeMeta || {},
    alias: _91id_93X0AUauIQxeMeta?.alias || [],
    redirect: _91id_93X0AUauIQxeMeta?.redirect || undefined,
    component: () => import("/app/pages/gallery/[id].vue").then(m => m.default || m)
  },
  {
    name: indextDyoPT7UeiMeta?.name ?? "gallery",
    path: indextDyoPT7UeiMeta?.path ?? "/gallery",
    meta: indextDyoPT7UeiMeta || {},
    alias: indextDyoPT7UeiMeta?.alias || [],
    redirect: indextDyoPT7UeiMeta?.redirect || undefined,
    component: () => import("/app/pages/gallery/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexUH62nDhvWJMeta?.name ?? "login-change-password",
    path: indexUH62nDhvWJMeta?.path ?? "/login/change-password",
    meta: indexUH62nDhvWJMeta || {},
    alias: indexUH62nDhvWJMeta?.alias || [],
    redirect: indexUH62nDhvWJMeta?.redirect || undefined,
    component: () => import("/app/pages/login/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: _91password_93LYR8HUijsPMeta?.name ?? "login-email-password",
    path: _91password_93LYR8HUijsPMeta?.path ?? "/login/email/:password()",
    meta: _91password_93LYR8HUijsPMeta || {},
    alias: _91password_93LYR8HUijsPMeta?.alias || [],
    redirect: _91password_93LYR8HUijsPMeta?.redirect || undefined,
    component: () => import("/app/pages/login/email/[password].vue").then(m => m.default || m)
  },
  {
    name: indexf7W4xrWuMDMeta?.name ?? "login-email",
    path: indexf7W4xrWuMDMeta?.path ?? "/login/email",
    meta: indexf7W4xrWuMDMeta || {},
    alias: indexf7W4xrWuMDMeta?.alias || [],
    redirect: indexf7W4xrWuMDMeta?.redirect || undefined,
    component: () => import("/app/pages/login/email/index.vue").then(m => m.default || m)
  },
  {
    name: indexgK5VicK7y2Meta?.name ?? "login",
    path: indexgK5VicK7y2Meta?.path ?? "/login",
    meta: indexgK5VicK7y2Meta || {},
    alias: indexgK5VicK7y2Meta?.alias || [],
    redirect: indexgK5VicK7y2Meta?.redirect || undefined,
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_937ZjP19jqf6Meta?.name ?? "open-calls-id",
    path: _91id_937ZjP19jqf6Meta?.path ?? "/open-calls/:id()",
    meta: _91id_937ZjP19jqf6Meta || {},
    alias: _91id_937ZjP19jqf6Meta?.alias || [],
    redirect: _91id_937ZjP19jqf6Meta?.redirect || undefined,
    component: () => import("/app/pages/open-calls/[id].vue").then(m => m.default || m)
  },
  {
    name: indexZvpMAgx9f3Meta?.name ?? "open-calls",
    path: indexZvpMAgx9f3Meta?.path ?? "/open-calls",
    meta: indexZvpMAgx9f3Meta || {},
    alias: indexZvpMAgx9f3Meta?.alias || [],
    redirect: indexZvpMAgx9f3Meta?.redirect || undefined,
    component: () => import("/app/pages/open-calls/index.vue").then(m => m.default || m)
  },
  {
    name: registrationre4qBsMVLdMeta?.name ?? "registration",
    path: registrationre4qBsMVLdMeta?.path ?? "/registration",
    meta: registrationre4qBsMVLdMeta || {},
    alias: registrationre4qBsMVLdMeta?.alias || [],
    redirect: registrationre4qBsMVLdMeta?.redirect || undefined,
    component: () => import("/app/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: selectionsgNFlPlxpAUMeta?.name ?? "selections",
    path: selectionsgNFlPlxpAUMeta?.path ?? "/selections",
    meta: selectionsgNFlPlxpAUMeta || {},
    alias: selectionsgNFlPlxpAUMeta?.alias || [],
    redirect: selectionsgNFlPlxpAUMeta?.redirect || undefined,
    component: () => import("/app/pages/selections.vue").then(m => m.default || m)
  }
]